var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._m(0),
      _c(
        "q-stepper",
        {
          ref: "stepper",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            animated: "",
            flat: "",
            "alternative-labels": "",
            "no-header-navigation": "",
            "no-shadow": "",
            "transition-next": "fade",
            "transition-prev": "slide-down",
            "active-color": "deep-orange",
            "done-color": "blue-grey-4",
          },
          model: {
            value: _vm.step,
            callback: function ($$v) {
              _vm.step = $$v
            },
            expression: "step",
          },
        },
        [
          _c(
            "q-step",
            {
              attrs: {
                name: 1,
                title: "Controllo documenti caricati",
                icon: "list",
                "done-icon": "done",
                "active-icon": "list",
                done: _vm.step > 1,
              },
            },
            [
              _c("ControlloDocumentiCaricati", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                  "on-segnala-problemi": _vm.onSegnalaProblemi,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 2,
                title: "Controllo dati del Cliente",
                icon: "grading",
                "done-icon": "done",
                "active-icon": "grading",
                done: _vm.step > 2,
              },
            },
            [
              _c("ControlloDatiCliente", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                  "on-segnala-problemi": _vm.onSegnalaProblemi,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 3,
                title: "Controllo Questionari",
                icon: "done_all",
                "done-icon": "done",
                "active-icon": "done_all",
                done: _vm.step > 3,
              },
            },
            [
              _c("ControlloQuestionari", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                  "on-segnala-problemi": _vm.onSegnalaProblemi,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 4,
                title: "Controllo dati dei Contratti",
                icon: "list_alt",
                "done-icon": "done",
                "active-icon": "list_alt",
                done: _vm.step > 4,
              },
            },
            [
              _c("ControlloPolizze", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                  "on-segnala-problemi": _vm.onSegnalaProblemi,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Controllo richiesta di emissione"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }