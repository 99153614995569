var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4 q-mr-sm" },
        [
          _c("h5", [_vm._v("Documenti richiesti per le proposte")]),
          _c(
            "q-list",
            { attrs: { bordered: "" } },
            _vm._l(_vm.elenco_documenti_richiesti, function (documento, index) {
              return _c(
                "q-item",
                { key: index },
                [
                  _c(
                    "q-item-section",
                    { attrs: { avatar: "" } },
                    [
                      _c("q-avatar", {
                        attrs: {
                          color: "orange-3",
                          "text-color": "black",
                          icon: "mdi-text-box-check-outline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("q-item-section", [
                    _vm._v(" " + _vm._s(documento.descrizione_documento) + " "),
                  ]),
                ],
                1
              )
            }),
            1
          ),
          _c("br"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-6" },
        [
          _c("QQElencoDocumentiPratica", {
            attrs: {
              title: "",
              tabIndex: "1",
              id_pratica: _vm.IDPratica,
              formPratica: _vm.formPratica,
            },
          }),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "row col-12 col-md-12 justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "Segnala problemi",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onSegnalaProblemi()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "AVANTI",
                color: "blue-grey",
                icon: "mdi-arrow-right-bold",
                size: "md",
                icon_align: "right",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h5" }, [
        _vm._v("Verifica dei documenti caricati"),
      ]),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }