var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-5 q-mr-sm" },
        [
          _c("h5", [_vm._v("Analisi necessità")]),
          _c(
            "q-list",
            { attrs: { bordered: "", separator: "" } },
            _vm._l(_vm.getDomandeAnalisiNecessita(), function (domanda, index) {
              return _c(
                "q-item",
                { key: index },
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v(_vm._s(domanda.label))]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(domanda.value)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c("br"),
          _c("h5", { staticClass: "q-mt-sm q-mb-md" }, [
            _vm._v("Analisi del Rischio"),
          ]),
          _vm._l(
            _vm.getDomandeAnalisiRischio(),
            function (sezione_domanda, index) {
              return _c(
                "q-list",
                {
                  key: index,
                  staticClass: "q-mb-md",
                  attrs: { bordered: "", separator: "" },
                },
                [
                  _c("q-item-label", { attrs: { header: "" } }, [
                    _vm._v(_vm._s(sezione_domanda.label)),
                  ]),
                  _vm._l(
                    sezione_domanda.domande.filter(function (d) {
                      return d.value !== ""
                    }),
                    function (domanda, indexDomanda) {
                      return _c(
                        "q-item",
                        { key: indexDomanda },
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", {
                                domProps: { innerHTML: _vm._s(domanda.label) },
                              }),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(_vm._s(_vm.analizzaRisposta(domanda))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              )
            }
          ),
          _c("br"),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-5 q-mr-sm" },
        [
          _c("QQElencoDocumentiPratica", {
            attrs: {
              title: "",
              tabIndex: "1",
              id_pratica: _vm.IDPratica,
              formPratica: _vm.formPratica,
            },
          }),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "row col-12 col-md-12 justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "Segnala problemi",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onSegnalaProblemi()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _vm.PuoLavorareLaPratica()
              ? _c("QQButton", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.PuoCancellareLaPratica(),
                      expression: "PuoCancellareLaPratica()",
                    },
                  ],
                  attrs: {
                    label: "Annulla Pratica",
                    color: "blue-grey",
                    icon: "mdi-delete",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onAnnullaPratica()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "AVANTI",
                color: "blue-grey",
                icon: "mdi-arrow-right-bold",
                size: "md",
                icon_align: "right",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h5" }, [
        _vm._v("Verifica dati dei Questionari"),
      ]),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }