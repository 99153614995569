var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-6 q-mr-sm" },
        [
          _c("h5", [_vm._v("Proposte selezionate")]),
          _vm._l(_vm.getProdottiSelezionati, function (prodotto, index) {
            return _c(
              "q-list",
              {
                key: index,
                staticClass: "q-mb-md",
                attrs: { separator: "", bordered: "" },
              },
              [
                _c(
                  "q-item-label",
                  { attrs: { header: "" } },
                  [
                    _c("q-avatar", {
                      attrs: {
                        color: "orange-3",
                        "text-color": "black",
                        icon: "mdi-check-outline",
                        size: "36px",
                      },
                    }),
                    _vm._v(" " + _vm._s(prodotto.label) + " "),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v(" Compagnia e Fornitore ")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " - Compagnia: " +
                              _vm._s(_vm.getCompagniaSelezionata(prodotto))
                          ),
                          _c("br"),
                          _vm._v(
                            " - Fornitore: " +
                              _vm._s(_vm.getFornitoreSelezionato(prodotto)) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v(" Date e Frazionamento ")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " - Decorrenza: " +
                              _vm._s(_vm.getDataDecorrenzaPratica)
                          ),
                          _c("br"),
                          _vm._v(
                            " - Scadenza: " +
                              _vm._s(_vm.getScadenzaContrattuale(prodotto))
                          ),
                          _c("br"),
                          _vm._v(
                            " - Frazionamento: " +
                              _vm._s(_vm.getFrazionamentoPratica(prodotto)) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v(" Premi rata ")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " - Premio rata: " +
                              _vm._s(_vm.getPremioRata(prodotto))
                          ),
                          _c("br"),
                          _vm._v(
                            " - Provvigioni rata: " +
                              _vm._s(_vm.getProvvigioniRata(prodotto)) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(
                  _vm.getPropostaSelezionata(prodotto).parametri,
                  function (garanzia, indexGaranzia) {
                    return _c(
                      "q-item",
                      _vm._b({ key: indexGaranzia }, "q-item", garanzia, false),
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _vm._v(" " + _vm._s(garanzia.label) + " "),
                            ]),
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.analizzaRisposta(garanzia)) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-5 q-mr-sm" },
        [
          _c("QQElencoDocumentiPratica", {
            attrs: {
              title: "",
              tabIndex: "3",
              id_pratica: _vm.IDPratica,
              formPratica: _vm.formPratica,
            },
          }),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "row col-12 col-md-12 justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "Segnala problemi",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onSegnalaProblemi()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _vm.PuoLavorareLaPratica()
              ? _c("QQButton", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.PuoCancellareLaPratica(),
                      expression: "PuoCancellareLaPratica()",
                    },
                  ],
                  attrs: {
                    label: "Annulla Pratica",
                    color: "blue-grey",
                    icon: "mdi-delete",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onAnnullaPratica()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "SALVA",
                color: "blue-grey",
                icon: "mdi-content-save-outline",
                size: "md",
                icon_align: "right",
                disabled: !_vm.pulsante_abilitato,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h5" }, [
        _vm._v("Verifica dati delle polizze"),
      ]),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }