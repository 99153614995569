import { render, staticRenderFns } from "./ControlloDatiCliente.vue?vue&type=template&id=451d4bf0&"
import script from "./ControlloDatiCliente.vue?vue&type=script&lang=js&"
export * from "./ControlloDatiCliente.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QItemLabel});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('451d4bf0')) {
      api.createRecord('451d4bf0', component.options)
    } else {
      api.reload('451d4bf0', component.options)
    }
    module.hot.accept("./ControlloDatiCliente.vue?vue&type=template&id=451d4bf0&", function () {
      api.rerender('451d4bf0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Pratiche/ControlloRichiestaEmissione/ControlloDatiCliente.vue"
export default component.exports